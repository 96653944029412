import act from '../main/action-const'
import { getCookie } from '../common/helper'

const USER_KEY = '__user_key__'
const AGREE_COOKIE = '__agree_cliente__'

let login_store = localStorage.getItem(USER_KEY)
try {
    login_store = (JSON.parse(login_store))
} catch (e) {
    login_store = null
}

let agree_cookie
try {
    agree_cookie = (getCookie(AGREE_COOKIE))
} catch (e) {
    agree_cookie = null
}

const INITIAL_STATE = {
    usuario: login_store,
    agree: agree_cookie,
    token_valido: false,
    login_error: false,
    change_error: null,
    login_esqueci: 0,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case act.TOKEN_VALIDADO:
            if (action.payload) {
                return { ...state, token_valido: true, login_error: false }
            } else {
                localStorage.removeItem(USER_KEY)
                return { ...state, token_valido: false, usuario: null, login_error: false }
            }

        case act.USUARIO_CARREGADO:
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload))
            return { ...state, usuario: action.payload, token_valido: true, login_error: false }

        case act.USUARIO_INVALIDO:
            localStorage.removeItem(USER_KEY)
            return { ...state, login_error: true }

        case act.USUARIO_TROCA_INVALIDA:
            return { ...state, change_error: action.payload }

        case act.GO_LOGIN_ESQUECI:
            return { ...state, login_esqueci: action.payload }

        case act.GO_USER_STATUS_CHANGE: {
            const info = state.usuario;
            info['status'] = action.payload
            return { ...state, usuario: info }
        }

        case act.GO_AGREE: {
            return { ...state, agree: action.payload }
        }

        
        case act.PERFIL_FOTO_CROP: {
            const info = state.usuario;
            info['fotofile'] = action.payload

            localStorage.setItem(USER_KEY, JSON.stringify(info))

            return { ...state, usuario: info }
        }


        default:
            return state
    }
}

