import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeYellow, BadgeBlue} from '../common/ui/badge'
import FloatButton from '../common/ui/float-button'

import {date_loc} from '../sys/normalize-location'

import {listar} from './os-action'
import config from '../main/config'


import OSFilter from './os-filter'

const bgcolors = {
    '1': 'bg-aqua',
    '2': 'bg-teal',
    '3': 'bg-purple',
    '4': 'bg-green',
    '5': 'bg-red',
    '6': 'bg-orange',
    '7': 'bg-blue',
    '9': 'bg-black',
}

// .bg-red,
// .bg-yellow,
// .bg-aqua,
// .bg-blue,
// .bg-light-blue,
// .bg-green,
// .bg-navy,
// .bg-teal,
// .bg-olive,
// .bg-lime,
// .bg-orange,
// .bg-fuchsia,
// .bg-purple,
// .bg-maroon,
// .bg-black,
// .bg-red-active,
// .bg-yellow-active,
// .bg-aqua-active,
// .bg-blue-active,
// .bg-light-blue-active,
// .bg-green-active,
// .bg-navy-active,
// .bg-teal-active,
// .bg-olive-active,
// .bg-lime-active,
// .bg-orange-active,
// .bg-fuchsia-active,
// .bg-purple-active,
// .bg-maroon-active,
// .bg-black-active,


class OS extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    renderOS(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'missao.png'

        return dados.map(vo => {
            const img_perfil =  (img_perfil_defa)
            return(
                <CardSecond
                    key={'os' + vo['id_os']} 
                    title={vo['cliente'] }
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={bgcolors[vo['sit_os']]}
                    minHeightHeader={'110px'}
                    route={`/missao-ficha/${vo['id_os']}`}
                >
                    <Item text='Programação'><BadgeYellow title={'O.S. '+vo['os_numero']}/><BadgeYellow title={date_loc(vo['dt_prog'])}/></Item>
                    <Item text='Celular'><BadgeYellow title={vo['celular']}/></Item>
                    <Item text='Email'><BadgeYellow title={vo['email']}/></Item>
                    <Item text='Contato'><BadgeYellow title={vo['contato']}/></Item>
                    <Item text='Documento'>
                        <BadgeBlue title={vo['sit_os_label']}/>
                        <BadgeYellow title={vo['documento']}/> 
                    </Item>
                </CardSecond>
            )
        })
    }

    render() {
        return(
            <PageSetup title='Misões' subtitle='Consulta'>
                <ContentSection>
                    
                    <Row>
                        <Grid cols='md-3'>
                            <OSFilter/>
                        </Grid>
                        <Grid cols='md-9'>
                            {this.renderOS(this.props.os.lista)}
                        </Grid>
                    </Row>
                </ContentSection>

                <FloatButton
                    route='/missao-add' icon="fa fa-plus" tooltip="Nova Missão"
                />

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({os: state.os, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OS)
