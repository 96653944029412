import React from 'react'
import { LabelSucess, LabelDanger, LabelDefault, LabelInfo, LabelWarning} from '../common/ui/labels' 

export const os_servico_situacao = (s) => {
    switch (s) {
        case '1': return <LabelSucess title='Programado'/>
        case '2': return <LabelWarning title='Andamento'/>
        case '7': return <LabelInfo title='Finalizado'/>
        case '8': return <LabelDanger title='Contestado'/>
        case '9': return <LabelDefault title='Cancelado'/>
        default: return <LabelDefault title='Indefinido'/>
    }

}