import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../home/home'
import PerfilShow from '../perfil/perfil-show'
import FichaShow from '../ficha/ficha-show'

import OS from '../os/os'
import OSAdd from '../os/os-add'
import OSEdit from '../os/os-edit'
import OSFichaShow from '../os/os-ficha-show'
import OSAprovar from '../os-aprovar/os-aprovar'
import Servicos from '../acompanhar/servicos'

export default props => (

    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/perfil-show' component={PerfilShow} />
        <Route exact path='/ficha/:id_pessoa' component={FichaShow} />

        <Route exact path='/missao' component={OS} />
        <Route exact path='/missao-edit/:id_os' component={OSEdit} />
        <Route exact path='/missao-add' component={OSAdd} />
        <Route exact path='/missao-ficha/:id_os' component={OSFichaShow} />
        <Route exact path='/aprovar' component={OSAprovar} />
        <Route exact path='/radar' component={Servicos} />
        
        <Redirect from='*' to="/" />
    </Switch>

)
