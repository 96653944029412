import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { GroupUnbordered, GroupItem } from '../common/widget/box'
import { LabelSucess, LabelInfo, LabelDanger, LabelDefault } from '../common/ui/labels'
import { InputGroup, SelectGroup, FormRow } from '../common/form'
import { hora_mask } from '../sys/normalize-mask'
import { date_time_loc, money_loc } from '../sys/normalize-location'

import { cancelar, finalizar, limpar } from './os-servico-action'
import { obter as obter_os} from '../os/os-action'

import Modal from '../common/ui/modal'

const os_servico_situacao = (s) => {
    switch (s) {
        case '1': return <LabelSucess title='Programado'/>
        case '2': return <LabelDanger title='Andamento'/>
        case '7': return <LabelInfo title='Finalizado'/>
        case '9': return <LabelDefault title='Cancelado'/>
        default: return <LabelDefault title='Indefinido'/>
    }
}

const selector = formValueSelector('os_servicoDetForm')

class OSServicosDetalhe extends Component {
/*
    constructor(props) {
        super(props)
    }
*/
    onSubmit(values) {
        let vo = {
            id_servico: this.props.os_servico.dados['id_servico'], 
            id_os: this.props.os_servico.dados['id_os'], 
            observacao: values.observacao, 
        }
        if(this.props.action === "1") {
            vo['dia_real'] =  values.dia_real
            vo['hora_real'] = values.hora_real
            vo['preco_adicional'] = values.preco_adicional || 0
            vo['placas'] = values.placas || ''
            vo['custo_adicional_total'] = values.custo_adicional_total || 0
            vo['combustivel'] = values.combustivel || 0
            vo['lavagem'] = values.lavagem || 0
            vo['estacionamento'] = values.estacionamento || 0
            vo['conducao'] = values.conducao || 0
            vo['alimentacao'] = values.alimentacao || 0
            vo['pedagio'] = values.pedagio || 0
            vo['outros'] = values.outros || 0
            vo['outros_custos_descricao'] = values.outros_custos_descricao
            vo['preco_abater'] = values.preco_abater
            vo['he_cobrar'] = values.he_cobrar
            vo['descontar_agente'] = values.descontar_agente
            vo['reembolsar_agente'] = values.reembolsar_agente
            vo['he_pagar'] = values.he_pagar
            this.props.finalizar(vo, this.props.obter_os )
        }
     
        if(this.props.action === "2") {
            this.props.cancelar(vo, obter_os)
        }
    }

    get_adicionais() {
        let retorno = ''

        if (parseFloat(this.props.os_servico.dados['preco_abater']) !== 0.0) {
            retorno += 'Preço Abater ' + money_loc(this.props.os_servico.dados['preco_abater']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['he_cobrar']) !== 0.0) {
            retorno += 'Cobrar HE ' + money_loc(this.props.os_servico.dados['he_cobrar']) + ' | '
        }

        if (parseFloat(this.props.os_servico.dados['combustivel']) !== 0.0) {
            retorno += 'Combustível ' + money_loc(this.props.os_servico.dados['combustivel']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['lavagem']) !== 0.0) {
            retorno += 'Lavagem ' + money_loc(this.props.os_servico.dados['lavagem']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['estacionamento']) !== 0.0) {
            retorno += 'Estacionamento ' + money_loc(this.props.os_servico.dados['estacionamento']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['conducao']) !== 0.0) {
            retorno += 'Condução ' + money_loc(this.props.os_servico.dados['conducao']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['alimentacao']) !== 0.0) {
            retorno += 'Alimentacao ' + money_loc(this.props.os_servico.dados['alimentacao']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['pedagio']) !== 0.0) {
            retorno += 'Pedágio ' + money_loc(this.props.os_servico.dados['pedagio']) + ' | '
        }
        if (parseFloat(this.props.os_servico.dados['outros']) !== 0.0) {
            retorno += 'Outros ' + money_loc(this.props.os_servico.dados['outros']) + ' | '
        }

        if (parseFloat(this.props.os_servico.dados['reembolsar_agente']) !== 0.0) {
            retorno += 'Reembolsar Ag. ' + money_loc(this.props.os_servico.dados['reembolsar_agente']) + ' | '
        }

        if (parseFloat(this.props.os_servico.dados['descontar_agente']) !== 0.0) {
            retorno += 'Descontar Ag. ' + money_loc(this.props.os_servico.dados['descontar_agente']) + ' | '
        }

        if (parseFloat(this.props.os_servico.dados['he_pagar']) !== 0.0) {
            retorno += 'Pagar HE Ag. ' + money_loc(this.props.os_servico.dados['he_pagar']) + ' | '
        }

        return retorno;
    }

    render_action() {
        return (
            <>
                <FormRow>
                    <Field label='Ação' name="action" component={SelectGroup}  cols='md-3'>
                        <option/>
                        <option value="1">Finalizar</option>
                        <option value="2">Cancelar</option>
                    </Field>
                    { (this.props.action === "1") &&
                    <FormRow>
                        <Field label="Dia Real" name="dia_real" placeholder="Dia Real" type='date' component={InputGroup}  cols='md-4' />
                        <Field label="Hora Real" name="hora_real"  normalize={hora_mask} component={InputGroup}  cols='md-2' />
                    </FormRow>
                }
                </FormRow>
                <div className="clearfix"></div>
                { (this.props.action === "1" || this.props.action === "2") &&
                    <FormRow>
                        <Field label='Observação' name="observacao" placeholder="Observação" component={InputGroup}  cols='md-12'/>
                    </FormRow>
                }
                
                { (this.props.action === "1") &&
                <FormRow>
                    <div className="clearfix"></div>
                    <Field label="Placas" name="placas"  component={InputGroup}  cols='md-12' />
                    <Field label='Preço Adicional' name="preco_adicional" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Preço Abater' name="preco_abater" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Cobrar HE' name="he_cobrar" type="number" component={InputGroup}  cols='md-3' />
                    <div className="clearfix"></div>
                    
                    <Field label='Combustível' name="combustivel" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Lavagem' name="lavagem" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Estacionamento' name="estacionamento" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Condução' name="conducao" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Alimentação' name="alimentacao" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Pedágio' name="pedagio" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Outros' name="outros" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Outros Descrição' name="outros_custos_descricao" placeholder="Outros Custos Descrição" component={InputGroup}  cols='md-12'/>
                    <div className="clearfix"></div>
                    <Field label='Reembolsar Ag.' name="reembolsar_agente" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Descontar Ag.' name="descontar_agente" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Pagar HE Ag.' name="he_pagar" type="number" component={InputGroup}  cols='md-3' />
                </FormRow>
                }
            </>
        )
    }

    render() {
        const { handleSubmit } = this.props
        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='os-servico-detalhe' 
                title="Serviço" 
                confirmButtonType={this.props.action === "1" || this.props.action === "2" ? "submit" : "hide"}
                confirmLabel={this.props.action === "1" ? "Finalizar Item" : "Cancelar Item"}
                onConfirm={()=>{ return true }}
                onClose={()=>{this.props.limpar()}}
            >
                {os_servico_situacao(this.props.os_servico.dados['sit_servico'])}
                <LabelInfo title={' criado em ' + date_time_loc(this.props.os_servico.dados['dh_inc'])} />
                <LabelInfo title={' por ' + this.props.os_servico.dados['login_inc'] || ''} />
                {(this.props.os_servico.dados['sit_servico'] === '9') && <div className="clearfix"></div> }
                {(this.props.os_servico.dados['sit_servico'] === '9') &&
                    <LabelDefault title={' cancelado em ' + date_time_loc(this.props.os_servico.dados['dh_alt'])} />
                }
                {(this.props.os_servico.dados['sit_servico'] === '9') &&
                    <LabelDefault title={' por ' + this.props.os_servico.dados['login_alt'] || ''} />
                }
                <GroupUnbordered>
                    <GroupItem label="Descrição" value={(this.props.os_servico.dados['descricao'] || '')+' | '+(this.props.os_servico.dados['ref'] || '') }></GroupItem>
                    <GroupItem label="Dia | Agente" value={
                        date_time_loc((this.props.os_servico.dados['dia_prog'] || '') +' '+ (this.props.os_servico.dados['hora_prog'] || '')) + ' | '+
                        this.props.os_servico.dados['agente'] || ''
                        }></GroupItem>
                    <GroupItem label="Preço | Preço Adicional" value={                        
                        (money_loc(this.props.os_servico.dados['preco'] || 0) || '')+' | '+
                        (money_loc(this.props.os_servico.dados['preco_adicional'] || 0) || '')
                    }></GroupItem>

                    <GroupItem label="Gatos Adicionais" value={this.get_adicionais()}></GroupItem>

                </GroupUnbordered>

                {this.render_action()}
                <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }
}

OSServicosDetalhe = reduxForm({
    form: 'os_servicoDetForm',
    enableReinitialize : true,
})(OSServicosDetalhe)


const mapStateToProps = state => ({
    os_servico: state.os_servico,
    action: selector(state, 'action')
})
const mapDispatchToProps = dispatch => bindActionCreators({cancelar, finalizar, limpar, obter_os}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSServicosDetalhe)

