import React from 'react'

import SimpleTable from '../common/tables/simple-table'
import { CalloutInfo } from '../common/msg/callout'

import { date_time_loc, money_loc } from '../sys/normalize-location'
import { os_servico_situacao } from '../os-servico/os-servico-labels'


const os_servico_detalhe_link = (obterDetalhe, limpar, row) => {
    return date_time_loc(row['dia_prog'] + ' ' + row['hora_prog']) || ''
    /*return (
    <Anchor onClick={() => {limpar(); obterDetalhe(row['id_servico'] || 0); modal_open('os-servico-detalhe')}}> {date_time_loc(row['dia_prog'] + ' '+ row['hora_prog']) || ''} </Anchor>
    )*/
}

const servico = (row) => {
    return <>{row['servico'] + ' ' + row['placas']}</>
}

export default props => (
    <SimpleTable
        title="Serviços"
        columns={[
            { name: 'dia_prog', title: 'Dia', value: ((row) => { return os_servico_detalhe_link(props.obterDetalhe, props.limpar, row) }) },
            { name: 'sit_servico', title: 'Situação', value: ((row) => { return os_servico_situacao(row['sit_servico'], row['sit_financeiro']) }) },
            { name: 'servico', title: 'Descricao/Placas', value: ((row) => { return servico(row) }) },
            { name: 'preco', title: 'Preço', value: ((row) => { return money_loc(row['preco']) }) },
            { name: 'custo_adicional_total', title: 'Adicional', value: ((row) => { return money_loc(row['custo_adicional_total']) }) },
            { name: 'agente', title: 'Agente' },
        ]}
        data={props.dados}
        row_key={(row) => ('os_servico' + row['id_servico'])}
    >
        {(!props.dados || props.dados.length < 1) &&
            <CalloutInfo>
                <h4>Sem serviços até o momento!</h4>
                <p>Aguarde ...</p>
            </CalloutInfo>
        }
    </SimpleTable>
)
