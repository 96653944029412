import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'

import CardProfile, { Item } from '../common/widget/card-profile'

import PessoaAcessoLabels from '../pessoa/pessoa-acesso-labels'

import { obter } from './ficha-action'

import config from '../main/config'

class FichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_pessoa'])        
    }

    render_perfil() {
        const img_perfil = (this.props.ficha['foto_file']) ? (this.props.login.usuario['cdn_endpoint'] + this.props.ficha['foto_file']) : (config().IMAGE_PATH + 'profile.jpg')
        return (
            <CardProfile
                image={img_perfil}
                name={this.props.ficha['nome'] || ''}
            >
                <Item label="Documento" value={this.props.ficha['documento']}></Item>
                <Item label="Celular" value={this.props.ficha['celular']}></Item>
                <Item label="E-mail" value={this.props.ficha['email']}></Item>
                <Item>
                    <PessoaAcessoLabels classe={(this.props.ficha['classe'] || '')} />
                </Item>
               
                
            </CardProfile>
        )
    }

    render() {

        return (
            <PageSetup title='Ficha'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login,
    ficha: state.ficha,
    inscricao: state.inscricao
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FichaShow)
