import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'ficha'

export const obter = (id_pessoa) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    return transmitir(vo, 'get', ROTA,
        (payload)=> ([
            { type: act.FICHA_CARREGADA, payload: payload },
        ])
    )
}

export const obter_quest = (id_pessoa, id_inscricao) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    vo['id_inscricao'] = id_inscricao
    return transmitir(vo, 'get', ROTA+'-quest',
        (payload)=> ({ type: act.FICHA_QUEST_CARREGADOS, payload: payload })
    )
}

export const obter_perguntas = (id_pessoa, id_inscricao) => {
    let vo = []
    vo['id_pessoa'] = id_pessoa
    vo['id_inscricao'] = id_inscricao
    return transmitir(vo, 'get', ROTA+'-pergunta-list',
        (payload)=> ({ type: act.FICHA_PERGUNTAS_CARREGADAS, payload: payload })
    )
}


export const obter_avaliacao = (id_inscricao) => {
    let vo = []
    vo['id_inscricao'] = id_inscricao
    return transmitir(vo, 'get', 'avaliacao',
        (payload)=> ({ type: act.FICHA_AVALIACAO_CARREGADAS, payload: payload })
    )
}

