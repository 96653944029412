import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import { Input } from '../common/form'
import Modal, { modal_open } from '../common/ui/modal'

import { date_time_loc, money_loc } from '../sys/normalize-location'
import config from '../main/config'

import { listar, listar_mais, set_servico_atual, contestar, confirmar } from './action'
import { os_servico_situacao } from '../os-servico/os-servico-labels'

import { ButtonInfo } from '../common/ui/buttons'

import Filter from './filter'

class Servicos extends Component {
/*
    constructor(props) {
        super(props);
        this.props.listar()
    }
*/
    render_servicos(list) {
        const img_profile = (config().IMAGE_PATH + 'profile.jpg')
        if (!list) {
            return (
                <div>Vazio</div>
            )
        }
        return (list).map(vo => {
            let box
            switch (vo['sit_servico']) {
                case '1': box = 'success'; break
                case '2': box = 'warning'; break
                case '7': box = 'info'; break
                case '8': box = 'danger'; break
                case '9': box = 'default'; break
                default: box = 'default'; break
            }
            return (
                <div className={`box box-${box}`} key={vo['id_servico']}>
                    <div className="box-header with-border">
                        <div className="user-block">
                            <img className="img-circle" src={img_profile} alt="Agente" />
                            <span className="username">{vo['agente']}</span>
                            <span className="description">
                                {date_time_loc(vo['dia_real'] + ' ' + vo['hora_real'])}
                                {vo['cliente'] ? ' | ' + vo['cliente'] : ''}
                                {' | '} <Link to={`/missao-ficha/${vo['id_os']}`}>{vo['os_numero']}</Link>
                            </span>
                        </div>
                    </div>
                    <div className="box-body">
                        <p>
                            {vo['servico']}  {vo['placas'] ? ' | ' + vo['placas'] : ''}
                            <span className="pull-right text-muted">
                                Preço: {money_loc(vo['preco'])}
                            </span>
                            <br></br>
                            <span className="pull-right text-muted">
                                Adicional: {money_loc(vo['preco_adicional'])}
                            </span>

                        </p>

                        {os_servico_situacao(vo['sit_servico'])}

                        {vo['sit_servico'] === '7' &&
                            <button onClick={() => {
                                document.getElementById('motivo').value = ''
                                this.props.set_servico_atual(vo['id_servico'])
                                modal_open('servico-contestar');
                            }
                            } type="button" className="btn btn-default btn-xs"><i className="fa fa-thumbs-o-down"></i> Contestar</button>
                        }

                        {vo['sit_servico'] === '8' &&
                            <button onClick={() => {
                                document.getElementById('motivo-conf').value = ''
                                this.props.set_servico_atual(vo['id_servico'])
                                modal_open('servico-confirmar');
                            }
                            } type="button" className="btn btn-default btn-xs"><i className="fa fa-thumbs-o-up"></i> Confirmar</button>
                        }

                    </div>
                </div>
            )
        })
    }

    render_contestar() {
        return (
            <Modal
                id='servico-contestar'
                title="Contestar Serviço"
                confirmLabel='Contestar'
                onConfirm={() => {
                    this.props.contestar(
                        this.props.acomp.id_servico_atual,
                        document.getElementById('motivo').value);
                    return true
                }}
            >
                Motivo da Contestação:
                <Input id="motivo" />
            </Modal>
        )
    }

    render_confirmar() {
        return (
            <Modal
                id='servico-confirmar'
                title="Confirmar Serviço"
                confirmLabel='Confirmar'
                onConfirm={() => {
                    this.props.confirmar(
                        this.props.acomp.id_servico_atual,
                        document.getElementById('motivo-conf').value);
                    return true
                }}
            >
                Motivo da Confirmação:
                <Input id="motivo-conf" />
            </Modal>
        )
    }

    render() {
        return (
            <PageSetup title='Radar de Serviços' subtitle='Consultas'>
                <ContentSection>

                    <Row>
                        <Grid cols='md-3'>
                            <Filter />
                        </Grid>
                        <Grid cols='md-9'>
                            {this.render_servicos(this.props.acomp.lista)}
                            {(this.props.acomp.tem_mais) &&
                                <ButtonInfo onClick={() => {
                                    this.props.listar_mais(this.props.acomp.page + 1, this.props.acomp.filtro)
                                }}>Carregar mais...</ButtonInfo>
                            }
                        </Grid>
                    </Row>
                </ContentSection>
                {this.render_contestar()}
                {this.render_confirmar()}
            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({
    acomp: state.acomp,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({
    listar,
    listar_mais,
    set_servico_atual,
    contestar,
    confirmar
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Servicos)
