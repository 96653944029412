import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import appReducer from '../main/app-reducer'
import LoginReducer from '../login/login-reducer'
import PerfilReducer from '../perfil/perfil-reducer'
import FichaReducer from '../ficha/ficha-reducer'
import dashReducer from '../dashboard/dashboard-reducer'

import osReducer from '../os/os-reducer'
import os_servicoReducer from '../os-servico/os-servico-reducer'
import os_aprovarReducer from '../os-aprovar/os-aprovar-reducer'
import lojasReducer from '../lojas/lojas-reducer'
import acompanhamentoReducer from '../acompanhar/acompanhamento-reducer'

const rootReducer = combineReducers({
    app: appReducer,
    login: LoginReducer,
    perfil: PerfilReducer,
    ficha: FichaReducer,
    toastr: toastrReducer,
    form: formReducer,
    dash: dashReducer,
    os: osReducer,
    os_servico: os_servicoReducer,
    os_aprovar: os_aprovarReducer,
    lojas: lojasReducer,
    acomp: acompanhamentoReducer
})

export default rootReducer