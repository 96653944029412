import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Anchor } from '../common/helper'


import { login, go_esqueci } from './login-actions'
import config from '../main/config'

import { Row, Grid } from '../common/layout'
import { Input } from '../common/form'

class LoginPage extends Component {

    componentDidMount() {
        document.body.classList.remove(config().THEME_NAME)
        document.body.classList.remove('sidebar-mini')
        document.body.classList.add('login-page')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page')
        document.body.classList.add(config().THEME_NAME)
        document.body.classList.add('sidebar-mini')
    }

    onSubmit(values) {
        const { login } = this.props
        login(values)
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <div className="login-box">
                {/*<div className="login-logo">
                <Anchor href="/"><img src="dist/img/logo-large.png" alt="logo"/></Anchor>
            </div>*/}
                <div className="login-logo">
                    <Anchor href="/">{config().NOME_SISTEMA || 'Nome Sistema'}</Anchor>
                </div>
                <div className="login-box-body">

                    <p className="login-box-msg">Informe usuário e senha</p>

                    <form onSubmit={handleSubmit(v => this.onSubmit(v))}>
                        <div className={`form-group has-feedback ${this.props.login_info.login_error ? 'has-error' : ''}`}>
                            <Field component={Input} type="usuario" name="usuario" placeholder="Email" />
                            <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                        </div>
                        <div className={`form-group has-feedback ${this.props.login_info.login_error ? 'has-error' : ''}`}>
                            <Field component={Input} type="password" name="senha" placeholder="Senha ou código de recuperação" />
                            <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                            <span className="help-block">{this.props.login_info.login_error ? 'Usuário ou senha inválida!' : ''}</span>
                        </div>
                        <Row>
                            {/*
                        <Grid cols="md-8">
                            
                            <div className="checkbox icheck">
                                <label>
                                    <input type="checkbox" /> Remember Me
                                </label>
                            </div>
                           
                        </Grid>
                         */}
                            <Grid cols="xs-4">
                                <button type="submit" className="btn btn-primary btn-block btn-flat">Entrar</button>
                            </Grid>
                        </Row>
                    </form>

                    <br />
                    <Anchor href="#" onClick={() => { this.props.go_esqueci(1) }}>Esqueci minha senha</Anchor><br />

                    <br />
                    <Anchor href="/termos-de-aceite-lgpd.html">Termo LGPD</Anchor>&nbsp;|&nbsp;
                    <Anchor href="/termos-e-condicoes.html">Termo de uso</Anchor>&nbsp;|&nbsp;
                    <Anchor href="/politica-de-privacidade.html">Política de Privacidade</Anchor>


                </div>
            </div>
        )
    }
}

LoginPage = reduxForm({ form: 'loginForm' })(LoginPage)
const mapStateToProps = state => ({ login_info: state.login })
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, go_esqueci }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
