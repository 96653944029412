import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Redirect } from 'react-router-dom'

import PageSetup from '../main/page-setup'
import { ContentSection} from '../common/layout'
import { BoxTitle, BoxBody, BoxFooter, Box } from '../common/widget/box'
import { InputGroup, InputTextAreaGroup, FormRow } from '../common/form'
import { ButtonInfo } from '../common/ui/buttons'

import { init, obter, salvar } from './os-action'
import { cel_mask, cpf_cnpj_mask } from '../sys/normalize-mask'

const validarFormulario  = (values) => {
    const erros = {}
    if ( !values.qtd_agentes_solicitados || (parseFloat(values.qtd_agentes_solicitados) < 1) )
        erros.qtd_agentes_solicitados = 'Número de Agentes é obrigatório.'

    if (!values.dt_prog) {
        erros.dt_prog = 'Programação inicial é obrigatória.'
    }
 
    if ( !values.descricao || values.descricao.length === 0 ) {
        erros.descricao = 'Por favor, informe a descrição.'
    }

    return erros
}


class OSEdit extends Component {

    constructor(props) {
        super(props);
        this.props.init()
        const { match: { params } } = this.props;
        this.props.obter(params['id_os'])
        this.salvarFormulario = this.salvarFormulario.bind(this)
    }

    onSubmit(values) {
        let dados = {
            id_os: this.props.os.dados['id_os'],
            contato: values.contato,
            dt_prog: values.dt_prog,
            descricao: values.descricao,
            qtd_agentes_solicitados: values.qtd_agentes_solicitados,
            endereco: values.endereco,
            referencia: values.referencia,
            endereco_destino: values.endereco_destino,
            referencia_destino: values.referencia_destino
        }
        this.props.salvar(dados)
    }

    salvarFormulario() {
        const { handleSubmit } = this.props
        handleSubmit( values => this.onSubmit(values))
    }

    pessoa_onSelected = (item) => {
        this.props.change("fatupara", item['nome'] || '')
        this.props.change("id_cliente_faturar", item['id_pessoa'] || 0)
    }

    renderBotoes() {
        const { pristine, submitting } = this.props
        return (
        <Box>
            <BoxFooter>
                <ButtonInfo type="submit" align_class="pull-right" icon="fa fa-floppy-o" hint="Salvar dados" disabled={pristine || submitting}>Salvar</ButtonInfo>

            </BoxFooter>
        </Box>
        )
    }

    renderAcoes() {
        const { pristine, submitting } = this.props
        return (
        <Box>
            <BoxFooter>
                <ButtonInfo type="submit" align_class="pull-right" icon="fa fa-floppy-o" hint="Pedir OK do Regional" disabled={pristine || submitting}></ButtonInfo>
            </BoxFooter>
        </Box>
        )
    }


    renderFormulario() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <PageSetup title='Missão' subtitle='Atualização'>
                <ContentSection>
                    {this.renderBotoes()}
                    <BoxTitle title="Cliente">
                        <BoxBody>    
                            <FormRow>
                                <Field label='Cliente' name="cliente" placeholder='Nome do Cliente' disabled component={InputGroup}  cols='md-4' />
                                <Field label='C.P.F./C.N.P.J' name="documento" placeholder='C.P.F./C.N.P.J' component={InputGroup} normalize={cpf_cnpj_mask} cols='md-4' disabled/>
                                <Field label='O.S.' name="os_numero" placeholder='O.S.' component={InputGroup} cols='md-4' disabled/>
                            </FormRow>
                            <div className="clearfix"></div>
                            <FormRow>
                                <Field label='Celular' name="celular" placeholder='Celular' disabled component={InputGroup} normalize={cel_mask} cols='md-4' />
                                <Field label='Email' name="email" type='email'  placeholder='Email' disabled component={InputGroup}  cols='md-4' />
                                <Field label='Contato' name="contato" placeholder='Contato' component={InputGroup}  cols='md-4' />
                                <Field label='Faturar Para' name="fatupara" component={InputGroup}  cols='md-4' disabled/>
                            </FormRow>
                        </BoxBody>

                    </BoxTitle>

                    <BoxTitle title="Detalhes">
                        <BoxBody>    
                            <FormRow>
                                <Field label='Agentes Solicitados' name="qtd_agentes_solicitados" type='number' required component={InputGroup}  cols='md-2' />
                                <Field label='Programação' name="dt_prog" type='date' required component={InputGroup}  cols='md-2' />
                                <Field label='Descrição' name="descricao"  required component={InputTextAreaGroup}  maxLength={1000} cols='md-12' />
                            </FormRow>

                        </BoxBody>
                    </BoxTitle>

                    <BoxTitle title="Endereço">
                        <BoxBody>    
                            <FormRow>
                                <Field label='Endereco' name="endereco" component={InputGroup}  cols='md-12' />
                                <Field label='Referencia' name="referencia" component={InputGroup}  cols='md-8' />
                                <Field label='Região' name="regiao" disabled component={InputGroup}  cols='md-4' />
                            </FormRow>
                        </BoxBody>
                    </BoxTitle>

                    <BoxTitle title="Destino">
                        <BoxBody>    
                            <FormRow>
                                <Field label='Endereço Destino' name="endereco_destino" component={InputGroup}  cols='md-12' />
                                <Field label='Referencia' name="referencia_destino" component={InputGroup}  cols='md-12' />
                            </FormRow>
                        </BoxBody>
                    </BoxTitle>
                    
                </ContentSection>

                

            </PageSetup>
            </form>

        )
    }

    render() {
        if (this.props.os.upd_status === 3) {
            //return <Redirect to={`/missao`} />
            return <Redirect to={`/missao-ficha/${this.props.os.dados['id_os']}`} />
        }
        return this.renderFormulario()
    }
}

OSEdit = reduxForm({
    form: 'osEditForm',
    validate: validarFormulario,
    enableReinitialize : true
})(OSEdit)


const mapStateToProps = state => ({
    os: state.os,
    initialValues: state.os.dados
})
const mapDispatchToProps = dispatch => bindActionCreators({init, obter, salvar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSEdit)

