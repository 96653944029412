import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obterContadores } from './dashboard-action'

import { Row } from '../common/layout'
// import Smallbox from '../common/widget/small-box'

class Contadores extends Component {
  
    componentDidMount() {
        this.props.obterContadores()
    }


    render() {
        return(
            <Row>

            </Row>
        )
    }

}

const mapStateToProps = state => ({dash: state.dash})
const mapDispatchToProps = dispatch => bindActionCreators({ obterContadores }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Contadores)

