import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {
    id_servico: 0,
    id_os: 0,
    id_agente: 0,
    sit_servico: 0,
    servico: '',
    dia_prog: '',
    hora_prog: '',
    dia_real: '',
    hora_real: '',
    recurso_ref: '',
    recurso_desc: '',
    preco: 0.0,
}
const ROTA = 'os-servico'


export const listar = (dados) => {
    let vo = []
    vo['id_os'] = dados['id_os']
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    return transmitir(vo, 'get', ROTA+'-list',
        (payload)=> ({ type: act.OS_SERVICO_LISTADA, payload: payload })
    )
}


export const obter = (id_servico) => {
    let vo = []
    vo['id_servico'] = id_servico
    return transmitir(vo, 'get', ROTA,
        (payload)=> ([
            { type: act.OS_SERVICO_CARREGADA, payload: payload[0] || {} },
            recibo_listar(id_servico)
        ]
        )
    )
}


export const salvar = (dados, obter_os) => {
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ([
            { type: act.OS_SERVICO_SALVA, payload: payload },
            listar({id_os: dados['id_os']}),
            obter_os(dados['id_os']),
            limpar()
        ])
    )
}


export const convidar = (dados, obter_os) => {
    return transmitir(dados, 'post', ROTA + '-convidar', 
        (payload)=> ([
            { type: act.OS_SERVICO_SALVA, payload: payload },
            listar({id_os: dados['id_os']}),
            obter_os(dados['id_os']),
            limpar()
        ])
    )
}


export const cancelar_convite = (dados, obter_os) => {
    return transmitir(dados, 'post', ROTA + '-cancelar-convite', 
        (payload)=> ([
            { type: act.OS_SERVICO_SALVA, payload: payload },
            listar({id_os: dados['id_os']}),
            obter_os(dados['id_os']),
            limpar()
        ])
    )
}


export const cancelar = (vo, obter_os) => {
    return transmitir(vo, 'post', ROTA+'-cancelar',
        (payload)=> ([
            { type: act.OS_SERVICO_CANCELADO, payload: payload || {} },
            limpar(),
            obter(vo['id_servico']),
            listar({id_os: vo['id_os']}),
            obter_os(vo['id_os']),
        ])
    )
}


export const finalizar = (vo, obter_os) => {
    return transmitir(vo, 'post', ROTA+'-finalizar',
        (payload)=> ([
            { type: act.OS_SERVICO_FINALIZADO, payload: payload || {} },
            limpar(),
            obter(vo['id_servico']),
            listar({id_os: vo['id_os']}),
            obter_os(vo['id_os']),
        ])
    )
}


export const limpar = () => {
    return [{type: act.OS_SERVICO_LIMPAR}]
}

export const recibo_listar = (id_servico) => {
    let vo = []
    vo['id_servico'] = id_servico
    return transmitir(vo, 'get', ROTA+'-recibo-list',
        (payload)=> ({ type: act.OS_SERVICO_RECIBO_LISTADA, payload: payload })
    )
}



export const init = () => {
    return [
        initialize('os_servicoAddForm', VALORES_INICIAIS)
    ]
}
