import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'os'

export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_os']) {
        vo['sit_os'] = dados['sit_os']
    }
    return transmitir(vo, 'get', 'os-list',
        (payload) => ({ type: act.OS_LISTADO, payload: payload })
    )
}


export const obter = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA,
        (payload) => ({ type: act.OS_CARREGADO, payload: payload[0] })
    )
}

export const obter_timeline = (id_os) => {
    let vo = []
    vo['id_os'] = id_os
    return transmitir(vo, 'get', ROTA + '-timeline',
        (payload) => ({ type: act.OS_TIMELINE_CARREGADO, payload: payload })
    )
}

export const salvar = (dados) => {
    return transmitir(dados, 'post', ROTA,
        (payload) => ({ type: act.OS_SALVO, payload: payload })
    )
}

export const incluir = (dados) => {
    return transmitir(dados, 'post', ROTA,
        (payload) => ({ type: act.OS_INCLUIDO, payload: payload })
    )
}

export const solicitar_autorizacao = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-solicitar-autorizacao',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const autorizar_os = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-autorizar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const reprovar_os = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-reprovar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const cancelar_os = (id_os) => {
    let vo = {}
    vo['id_os'] = id_os
    return transmitir(vo, 'post', ROTA + '-cancelar',
        (payload) => ([
            { type: act.OS_SALVO, payload: payload },
            obter(id_os),
            obter_timeline(id_os),
        ])
    )
}

export const init = () => {
    return [
        initialize('osEditForm', VALORES_INICIAIS)
    ]
}
