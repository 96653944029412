import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { ButtonInfo, ButtonDanger, ButtonSucess } from '../common/ui/buttons'

import Modal, { modal_open } from '../common/ui/modal'

import { Icon, TexMuted } from '../common/ui'
import { Link } from 'react-router-dom'

import OSServicosDetalhe from '../os-servico/os-servico-detalhe'
import OSServicoList from '../os-servico/os-servico-list'
import OsTimeLine from '../os/os-time-line'
import { os_situacao } from './os-labels'

import { date_loc } from '../sys/normalize-location'

import { obter, solicitar_autorizacao, autorizar_os, reprovar_os, cancelar_os, obter_timeline } from './os-action'
import {
    listar as listar_servicos,
    limpar as limpar_servico,
    obter as obterDetalhe
} from '../os-servico/os-servico-action'

import config from '../main/config'


class OSFichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_os'])
        this.props.listar_servicos({ field: 'id_os', value: params['id_os'] })
        this.props.obter_timeline(params['id_os'])
    }

    render_sobre() {
        const img_missao = config().IMAGE_PATH + 'missao.png'
        const os_do_usuario = (this.props.os.dados['login_inc'] === this.props.os.dados['login_atual'])
        const os_em_edicao = parseInt(this.props.os.dados['sit_os']) === 1
        const os_em_aprovacao = parseInt(this.props.os.dados['sit_os']) === 3
        const os_aprovada = parseInt(this.props.os.dados['sit_os']) === 4
        const eh_um_gerente = (this.props.os?.dados['classe'] || '').includes('gerente')
        let botoes = []
        if (os_em_edicao && os_do_usuario) {
            botoes.push(<Link key={'1e'} to={`/missao-edit/${this.props.os.dados['id_os']}`} className="btn btn-primary btn-block">Editar Missão</Link>)
            botoes.push(<ButtonInfo key={'2ok'} align_class="btn-block" onClick={() => { modal_open('os-sol-autorizacao') }}>Pedir OK do Regional</ButtonInfo>)
        }
        if (os_em_aprovacao && eh_um_gerente) {
            botoes.push(<ButtonSucess key={'3a'} align_class="btn-block" onClick={() => { modal_open('os-autorizar') }}>Aprovar</ButtonSucess>)
            botoes.push(<ButtonDanger key={'4c'} align_class="btn-block" onClick={() => { modal_open('os-reprovar') }}>Reprovar</ButtonDanger>)
        }
        if ((os_em_edicao || os_em_aprovacao || os_aprovada) && (os_do_usuario)) {
            botoes.push(<ButtonDanger key={'5c'} align_class="btn-block" onClick={() => { modal_open('os-cancelar') }}>Cancelar OS</ButtonDanger>)
        }

        function botoes_show() {
            if (botoes.length < 1) return <></>
            return botoes.map(c => { return c })
        }

        return (
            <div className="box box-primary">
                <div className="box-header with-border">
                    <div className="user-block">
                        <img className="img-circle" src={img_missao} alt="Missão" />
                        <span className="username">{(this.props.os.dados['cliente'] || '')} </span>
                        <span className="description">{this.props.os.dados['documento'] +' | '+ this.props.os.dados['os_numero']}</span>
                    </div>

                </div>

                <div className="box-body">
                    <span className="pull-right">{os_situacao(this.props.os.dados['sit_os'])}</span>
                    <strong>
                        <Icon icon="fa fa-commenting" /> Descrição
                    </strong>
                    <p>{this.props.os.dados['descricao']}</p>

                    <strong>
                        <Icon icon="fa fa-calendar" /> Programação
                    </strong>
                    <TexMuted>{date_loc(this.props.os.dados['dt_prog'])}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-user-secret" /> Agentes 
                    </strong>
                    <TexMuted>{this.props.os.dados['qtd_agentes_solicitados'] || 0} Solicitados</TexMuted>

                    <strong>
                        <Icon icon="fa fa-map-marker" /> Local
                    </strong>                    
                    <TexMuted>{this.props.os.dados['endereco'] || ''}</TexMuted>
                    {this.props.os.dados['referencia'] && <TexMuted>{this.props.os.dados['referencia']}</TexMuted>}
                    {this.props.os.dados['endereco_destino'] &&
                        <span>
                            <strong>
                                <Icon icon="fa fa-map-marker" /> Destino
                            </strong>
                            <TexMuted>{this.props.os.dados['endereco_destino'] || ''}</TexMuted>
                            {this.props.os.dados['referencia_destino'] && <TexMuted>{this.props.os.dados['referencia_destino']}</TexMuted>}
                        </span>
                    }
                    <TexMuted>{this.props.os.dados['regiao'] || ''}</TexMuted>

                    <strong>
                        <Icon icon="fa fa-mobile-phone" /> Celular
                    </strong>
                    <TexMuted>{this.props.os.dados['celular'] + ' ' + (this.props.os.dados['contato'] || '')}</TexMuted>
                    <strong>
                        <Icon icon="fa fa-envelope" /> Email
                    </strong>
                    <TexMuted>{this.props.os.dados['email']}</TexMuted>


                    {botoes_show()}
                </div>                
                <OsTimeLine/>
            </div>
        )
    }

    render_sol_autoricacao() {
        return (
            <Modal
                id='os-sol-autorizacao'
                title="Autorização do Regional"
                confirmLabel='Solicitar'
                // confirmButtonType='submit'
                onConfirm={() => { this.props.solicitar_autorizacao(this.props.os.dados['id_os']); return true }}
            >
                <p>Deseja solicitar o OK do Regional para dar continuidade a está Missão?</p>
            </Modal>
        )
    }

    render_autorizar() {
        return (
            <Modal
                id='os-autorizar'
                title="Autorizar a Missão"
                confirmLabel='Autorizar'
                // confirmButtonType='submit'
                onConfirm={() => { this.props.autorizar_os(this.props.os.dados['id_os']); return true }}
            >
                <p>Autoriza a execução da OS?</p>
            </Modal>
        )
    }

    render_reprovar() {
        return (
            <Modal
                id='os-reprovar'
                title="Reprovar a Missão"
                confirmLabel='Reprovar'
                // confirmButtonType='submit'
                onConfirm={() => { this.props.reprovar_os(this.props.os.dados['id_os']); return true }}
            >
                <p>Reprova a execução da OS?</p>
            </Modal>
        )
    }

    render_cancelar() {
        return (
            <Modal
                id='os-cancelar'
                title="Cancelar a Missão"
                confirmLabel='Cancelar OS'
                onConfirm={() => { this.props.cancelar_os(this.props.os.dados['id_os']); return true }}
            >
                <p>Deseja cancelar a OS?</p>
            </Modal>
        )
    }

    render() {
        return (
            <PageSetup title='Ficha da Missão'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_sobre()}
                        </Grid>
                        <Grid cols="md-8">
                            <OSServicoList
                                dados={this.props.os_servico.lista}
                                obterDetalhe={(id_servico) => { this.props.obterDetalhe(id_servico) }}
                                limpar={() => { this.props.limpar_servico() }}
                            />
                        </Grid>
                    </Row>
                </ContentSection>
                <OSServicosDetalhe />

                {this.render_sol_autoricacao()}
                {this.render_autorizar()}
                {this.render_reprovar()}
                {this.render_cancelar()}

            </PageSetup>
        )
    }
}

const mapStateToProps = state => ({
    os: state.os,
    os_servico: state.os_servico
})
const mapDispatchToProps = dispatch => bindActionCreators({
    obter, listar_servicos, limpar_servico, obterDetalhe, solicitar_autorizacao, autorizar_os, reprovar_os, cancelar_os, obter_timeline
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSFichaShow)

