import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'
import CardSecond, { Item } from '../common/widget/card-second'
import { BoxSimple } from '../common/widget/box'
import { BadgeYellow, BadgeBlue } from '../common/ui/badge'
import { CalloutInfo } from '../common/msg/callout'

import { date_loc } from '../sys/normalize-location'

import { listar } from './os-aprovar-action'
import config from '../main/config'


const bgcolors = {
    '1': 'bg-aqua',
    '2': 'bg-teal',
    '3': 'bg-purple',
    '4': 'bg-green',
    '5': 'bg-red',
    '6': 'bg-orange',
    '7': 'bg-blue',
    '9': 'bg-black',
}

class OSAprovar extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    renderOS(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'missao.png'
        if (!dados.length) {
            return (
                <BoxSimple>
                    <CalloutInfo>
                        <h4>Por enquanto nada para aprovar!</h4>
                        <p>Aguarde as solicitações</p>
                    </CalloutInfo>
                </BoxSimple>
            )
        }

        return dados.map(vo => {
            const img_perfil = (img_perfil_defa)
            return (
                <CardSecond
                    key={'os' + vo['id_os']}
                    title={vo['cliente']}
                    image={img_perfil}
                    col_class="col-md-4"
                    color_class={bgcolors[vo['sit_os']]}
                    minHeightHeader={'110px'}
                    route={`/missao-ficha/${vo['id_os']}`}
                >
                    <Item text='Programação'><BadgeYellow title={'O.S. ' + vo['os_numero']} /><BadgeYellow title={date_loc(vo['dt_prog'])} /></Item>
                    <Item text='Celular'><BadgeYellow title={vo['celular']} /></Item>
                    <Item text='Email'><BadgeYellow title={vo['email']} /></Item>
                    <Item text='Contato'><BadgeYellow title={vo['contato']} /></Item>
                    <Item text='Documento'>
                        <BadgeBlue title={vo['sit_os_label']} />
                        <BadgeYellow title={vo['documento']} />
                    </Item>
                </CardSecond>
            )
        })
    }

    render() {
        return (
            <PageSetup title='Aprovações Pendentes' subtitle='Lista'>
                <ContentSection>
                    <Row>
                        <Grid cols='md-12'>
                            {this.renderOS(this.props.os.lista)}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({ os: state.os_aprovar, login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSAprovar)
