import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { logout } from '../login/login-actions'

import MenuItem from '../common/template/menu-item'

class MenuPrincipal extends Component {

    tem_acesso(acesso) {
        let classe = ((this.props.login.usuario['classe'] || '').split(',') || []).filter(i => i)
        for (let i in classe) {
            if (acesso.includes(classe[i])) {
                return true
            }
        }
        return false
    }

    render() {
        return (
            <React.Fragment>
                <MenuItem route="/home" label="Home" icon="fa fa-home" />
                <MenuItem route="/perfil-show" label="Meu Perfil" icon="glyphicon glyphicon-user" />
                <MenuItem route="/missao" label="Missões" icon="fa fa-rocket" />
                <MenuItem route="/aprovar" label="Aguardando OK" icon="glyphicon glyphicon-pencil" />
                <MenuItem route="/radar" label="Radar de Serviços" icon="fa fa-feed" />
                <MenuItem label="Sair" icon="fa fa-close" onClick={this.props.logout} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({ login: state.login })
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MenuPrincipal)
