import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { Input, SelectGroup } from '../common/form'
import { BoxSimple } from '../common/widget/box'

import { listar } from './action'

const selector = formValueSelector('ServicoPesquisaForm')

class Filter extends Component {

    onSubmit(values) {
        let dados = {
            dia: values.dia,
            ate: values.ate,
            periodo_tipo: values.pesq_periodo_tipo,
            field: values.pesqcampo,
            value: values.pesqvalor,
            sit_servico: values.pesq_sit_servico
        }
        this.props.listar(dados)
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <BoxSimple>
                <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                    <Field name="pesq_periodo_tipo" component={SelectGroup}>
                        <option value="">Qualquer Período</option>
                        <option value="1">Hoje</option>
                        <option value="2">Amanhã</option>
                        <option value="3">Ontem</option>
                        <option value="4">Esta Semana</option>
                        <option value="5">Proxima Semana</option>
                        <option value="6">Última Semana</option>
                        <option value="7" default>Está Quinzena</option>
                        <option value="8">Próxima Quinzena</option>
                        <option value="9">Quinzena Anterior</option>
                        <option value="10">Este Mês</option>
                        <option value="11">Proxima Mês</option>
                        <option value="12">Último Mês</option>
                        <option value="13">Últimos 15 dias</option>
                        <option value="14">Próximos 15 dias</option>
                        <option value="0">Informar Período</option>
                    </Field>
                    {this.props.action_periodo === "0" &&
                        < span >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}>Dia</span>
                                <Field name="dia" placeholder="Dia" type="date" component={Input} ></Field>
                            </span>
                            <br />
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}>Até</span>
                                <Field name="ate" placeholder="Até" type="date" component={Input} ></Field>
                            </span>
                            <br />
                        </span>
                    }

                    <Field name="pesq_sit_servico" component={SelectGroup}>
                        <option value="">Todas as Situações</option>
                        <option value="1">Programado</option>
                        <option value="2">Em Andamento</option>
                        <option value="7">Finalizada</option>
                        <option value="8">Contestado</option>
                        <option value="9">Cancelada</option>
                    </Field>

                    <Field name="pesqcampo" component={SelectGroup} defaultValue="cliente">
                        <option value="cliente">Pesquisar por Loja</option>
                        <option value="os_numero">Pesquisar por OS</option>
                        <option value="placa">Pesquisar por Placa</option>
                    </Field>

                    <div className="input-group input-group-sm">
                        <Field name="pesqvalor" component={Input} className="form-control" />
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info btn-flat">GO!</button>
                        </span>
                    </div>

                </form>
            </BoxSimple >
        )
    }

}

Filter = reduxForm({
    form: 'ServicoPesquisaForm',
    initialValues: { pesqcampo: 'cliente'}
})(Filter)

const mapStateToProps = state => ({
    action_periodo: selector(state, 'pesq_periodo_tipo')
})
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Filter)
