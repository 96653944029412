import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import CardProfile, { Item } from '../common/widget/card-profile'
import { ButtonPrimary } from '../common/ui/buttons'
import Crop from '../tools/crop'

import { FileUpload } from '../common/ui/fileupload'
import { recuperar_dados_sync } from '../sys/transmitir'
import { crop_foto, obter } from './perfil-action'
import { go_pwd_change } from '../login/login-actions'

import config from '../main/config'

class PerfilShow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: ""
        };

        this.props.obter()

        this.handle_upload_foto = this.handle_upload_foto.bind(this)
        this.handle_crop_foto = this.handle_crop_foto.bind(this)
        this.handle_cancelar_crop = this.handle_cancelar_crop.bind(this)
    }

    handle_upload_foto(data) {
        recuperar_dados_sync(data, 'post', 'perfil-foto-upload').then(ret =>
            this.setState({ image: ret['arquivo'] }))
    }

    handle_crop_foto(coordenadas) {
        coordenadas['image'] = this.state.image
        this.props.crop_foto(coordenadas)
        this.setState({ image: "" })
    }

    handle_cancelar_crop() {
        this.setState({ image: "" })
    }

    render_foto_perfil() {
        return (
            <PageSetup title='Foto do Perfil'>
                <ContentSection>
                    <Crop
                        image={this.state.image}
                        aspect={1 /*4 /3*/}
                        save_handle={this.handle_upload_foto}
                        crop_handle={this.handle_crop_foto}
                        crop_cancelar_handle={this.handle_cancelar_crop}
                    />
                </ContentSection>
            </PageSetup>
        )

    }


    render_perfil() {
        const img_perfil = (this.props.login.usuario['fotofile']) ? (this.props.login.usuario['cdn_endpoint'] + this.props.login.usuario['fotofile']) : (config().IMAGE_PATH + 'profile.jpg')
        return (
            <PageSetup title='Perfil' subtitle="Informações gerais">
                <ContentSection>
                    <Grid cols="md-4">
                        <CardProfile
                            image={img_perfil}
                            name={this.props.perfil['nome'] || ''}
                            title={this.props.perfil['classe']}
                            >
                            <FileUpload 
                                btn_class="primary btn-block" label='Carregar Foto'
                                mimeType={['image/png', 'image/jpeg']}
                                maxFileSize={1}
                                afterSelectFile={(data) => { this.handle_upload_foto(data) }}
                            />
                            <Item label="Documento" value={this.props.perfil['documento']}></Item>
                            <Item label="Celular" value={this.props.perfil['celular']}></Item>
                            <Item label="E-mail" value={this.props.perfil['email']}></Item>
                            <ButtonPrimary align_class="btn-block" onClick={this.props.go_pwd_change}>Mudar Senha</ButtonPrimary>
                        </CardProfile>
                    </Grid>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {

        return (
            <>
            {!this.state.image && this.render_perfil()}
            {this.state.image && this.render_foto_perfil()}
            </>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login,
    perfil: state.perfil
})
const mapDispatchToProps = dispatch => bindActionCreators({ crop_foto, obter, go_pwd_change }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PerfilShow)
