export default {
    APP_DATA_UPDATE: 'app_data_update',
    TOKEN_VALIDADO: 'token_valido',
    USUARIO_CARREGADO: 'usuario_carregado',
    USUARIO_INVALIDO: 'usuario_invalido',
    USUARIO_TROCA_INVALIDA: 'usuario_troca_invalida',
    GO_LOGIN_ESQUECI: 'go_login_esqueci',
    GO_USER_STATUS_CHANGE: 'go_user_status_change',
    GO_AGREE: 'go_agree',

    PERFIL_FOTO_CROP: 'perfil_foto_crop',
    PERFIL_CARREGADO: 'perfil_carregado',
    FICHA_CARREGADA: 'ficha_carregada',
    FICHA_QUEST_CARREGADOS: 'ficha_quest_carregados',  
    FICHA_PERGUNTAS_CARREGADAS: 'ficha_perguntas_carregadas',  
    FICHA_AVALIACAO_CARREGADAS: 'ficha_avaliacao_carregadas',  

    OS_CARREGADO: 'os_carregado',
    OS_PESQUISADO: 'os_pesquisado',
    OS_LISTADO: 'os_listado',
    OS_SALVO: 'os_salvo',    
    OS_INCLUIDO: 'os_incluido',
    OS_TIMELINE_CARREGADO: 'os_timeline_carregado',

    OS_SERVICO_CARREGADA: 'os_servico_carregado',
    OS_SERVICO_PESQUISADA: 'os_servico_pesquisado',
    OS_SERVICO_LISTADA: 'os_servico_listado',
    OS_SERVICO_SALVA: 'os_servico_salvo',
    OS_SERVICO_CANCELADO: 'os_servico_cancelado',
    OS_SERVICO_FINALIZADO: 'os_servico_finalizado',
    OS_SERVICO_LIMPAR: 'os_servico_limpar',
    OS_SERVICO_DADOS_ATUALIZAR: 'os_servico_atualizar',

    OS_APROVAR_LISTADO: 'os_aprovar_listado',

    LOJAS_LISTADAS: 'lojas_listadas',

    ACOMPANHAMENTO_LISTADO: 'acompanhamento_listado',
    ACOMPANHAMENTO_LISTADO_SAVE_FILTRO: 'acompanhamento_listado_save_filtro',
    ACOMPANHAMENTO_LISTADO_MAIS: 'acompanhamento_listado_mais',
    ACOMPANHAMENTO_ADD_PAGE: 'acompanhamento_add_page',
    ACOMPANHAMENTO_SERVICO_ATUAL: 'acompanhamento_servico_atual',
    ACOMPANHAMENTO_SALVO: 'acompanhamento_salvo',

    DASH_CONTADOR_CARREGADO: 'dash_contador_carregado',

}
