import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Redirect } from 'react-router-dom'

import { init, incluir } from './os-action'
import { listar as listar_loja } from '../lojas/lojas-action'

import { cel_mask, cpf_cnpj_mask } from '../sys/normalize-mask'

import PageSetup from '../main/page-setup'
import { ContentSection, Row, Grid } from '../common/layout'

import { BoxTitle, BoxBody, BoxFooter } from '../common/widget/box'
import { InputGroupAddon, SelectGroup, FormRow } from '../common/form'
import { ButtonInfo } from '../common/ui/buttons'

const validarFormulario  = (values) => {
    const erros = {}
    if ( !values.nome || values.nome.length === 0 )
        erros.nome = 'Informe o nome'
        
    return erros
}


const selector = formValueSelector('os_AddForm')

class OSAdd extends Component {

    componentDidMount() {
        this.props.listar_loja()
    }

    onSubmit(values) {
        let dados = {
            id_cliente: values.id_cliente,
            // contato: values.contato,
            documento: values.documento,
            celular: values.celular,
            email: values.email
        }
        this.props.incluir(dados)
    }

    loja_selected = (e) => {
        const index = e.target.value || -1
        const loja = this.props.lojas.lista[index]
        this.props.change("id_cliente", loja?.id_cliente)
        this.props.change('contato', loja?.contato )
        this.props.change("documento", loja?.documento )
        this.props.change("email", loja?.email )
        this.props.change("celular", loja?.celular )
    }

    render_combo_loja() {
        return (
            <>
            {(this.props.lojas?.lista || []).map( (loja, i) => 
                <option key={loja.id_cliente} value={i}>{loja.nome}</option>
            )}
            </>
        )
    }

    renderFormulario() {
        const { handleSubmit, pristine, submitting } = this.props
        return(
            <BoxTitle title="Nova Missão">
                <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
                <BoxBody>   

                    <FormRow>
                        <Field label='Loja' name="combo_loja" component={SelectGroup} onChange={this.loja_selected}>
                            <option/>
                            {this.render_combo_loja()}
                        </Field>
                    </FormRow>

                    <FormRow>
                        {/* <Field label='Contato' name="contato" icon='fa fa-user' placeholder='Nome do Contato' component={InputGroupAddon}  readOnly={true} cols='md-4' />
                        <br/> */}
                        <Field label='Celular' name="celular" icon='fa fa-mobile' placeholder='Celular' component={InputGroupAddon}  readOnly={true} normalize={cel_mask} cols='md-4' />
                        <br/>
                        <Field label='C.N.P.J.' name="documento" icon='fa fa-file-photo-o' placeholder='C.P.F./C.N.P.J.' component={InputGroupAddon} readOnly={true} normalize={cpf_cnpj_mask} cols='md-4' />
                        <br/>
                        <Field label='Email' name="email" type='email' icon='fa fa-envelope' placeholder='Email' required component={InputGroupAddon}  readOnly={true} cols='md-4' />
                    </FormRow>
                </BoxBody>

                <BoxFooter>
                    <ButtonInfo type="submit" align_class="pull-right" disabled={pristine || submitting}>Incluir</ButtonInfo>
                </BoxFooter>

                </form>
            </BoxTitle>

        )

    }

    renderPagina() {
        return(
            <PageSetup title='Misões'>
                <ContentSection>
                    <Row>
                        <Grid cols='md-4'>
                            {this.renderFormulario()}
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        if (this.props.os.upd_status === 5) {
            return <Redirect to={`/missao-edit/${this.props.os.dados['id_os']}`} />
        }
        return this.renderPagina()
    }
}

OSAdd = reduxForm({
    form: 'osAddForm',
    validate: validarFormulario
})(OSAdd)


const mapStateToProps = state => ({
    os: state.os,
    lojas: state.lojas,
    combo_loja: selector(state, 'combo_loja'),
})
const mapDispatchToProps = dispatch => bindActionCreators({init, listar_loja, incluir}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(OSAdd)

