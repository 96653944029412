import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'lojas'

export const listar = () => {
    return transmitir([], 'get', ROTA,
        (payload)=> ({ type: act.LOJAS_LISTADAS, payload: payload })
    )
}
