import { transmitir } from '../sys/transmitir'
import act from '../main/action-const'

const ROTA = 'os-show'

export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[dados['field']] = dados['value']
    }
    if (dados && dados['sit_servico']) {
        vo['sit_servico'] = dados['sit_servico']
    }
    if (dados && dados['dia']) {
        vo['dia'] = dados['dia']
    }
    if (dados && dados['ate']) {
        vo['ate'] = dados['ate']
    }
    if (dados && dados['periodo_tipo']) {
        vo['periodo_tipo'] = dados['periodo_tipo']
    }
    
    return transmitir(vo, 'get', ROTA + '-list',
        (payload) => ([
            { type: act.ACOMPANHAMENTO_LISTADO_SAVE_FILTRO, payload: vo },
            { type: act.ACOMPANHAMENTO_LISTADO, payload: payload }
        ])
    )
}

export const listar_mais = (page, filtro) => {
    filtro['page'] = page;
    return transmitir(filtro, 'get', ROTA + '-list',
        (payload) => ([
            { type: act.ACOMPANHAMENTO_LISTADO_MAIS, payload: payload },
            { type: act.ACOMPANHAMENTO_ADD_PAGE, payload: page }
        ])
    )
}

export const set_servico_atual = (id_servico) => {
    return (dispatch) => {
        dispatch({ type: act.ACOMPANHAMENTO_SERVICO_ATUAL, payload: id_servico })
    }
}

export const contestar = (id_servico, motivo) => {
    return transmitir({id_servico, motivo}, 'post', ROTA+'-contestar',
        (payload) => ({ type: act.ACOMPANHAMENTO_SALVO, payload: payload })
    )
}

export const confirmar = (id_servico, motivo) => {
    return transmitir({id_servico, motivo}, 'post', ROTA+'-confirmar',
        (payload) => ({ type: act.ACOMPANHAMENTO_SALVO, payload: payload })
    )
}
